import {inject, observer} from "mobx-react";
import {IFieldProps} from "../i-db-field-props";
import {FormFeedback, FormGroup, Input, Label} from "reactstrap";
import {action} from "mobx";
import * as React from "react";
import {ILangKeys} from "../../../language/ILang";


export const FieldPassword =
    inject('rootStore')(
        observer(
            (props: IFieldProps<any>) => {

                let {rootStore: {langStore: {lang}}, column, actual, error, readOnly} = props;
                const label: string = lang[column.label as ILangKeys] ? lang[column.label as ILangKeys] : column.fieldName as string;

                return (
                    <FormGroup>
                        <Label>
                            {column.primaryKey ? (<b>{label}</b>) : label}
                        </Label>

                        <Input id={column.fieldName as string}
                               name={column.fieldName as string}
                               type="password"
                               disabled={readOnly}
                               autoComplete="chrome-off" // https://stackoverflow.com/questions/15738259/disabling-chrome-autofill
                               value={actual[column.fieldName] !== null ? actual[column.fieldName] : ''}
                               onChange={action((e: any) => {
                                   actual[e.target.name] = e.target.value;
                               })}
                               invalid={!!error}
                               bsSize="sm"
                        />
                        {error ? <FormFeedback>{error}</FormFeedback> : null}
                    </FormGroup>);
            }
        )
    );