import * as React from 'react';
import {inject, observer} from "mobx-react";
import styled from "styled-components";


const _BaseCardHeaderTitle = inject('rootStore')(observer((props: any) => {
    const {rootStore: {langStore: {lang}}, title, className} = props;
    return (
        <div className={className}>
            {lang[title]}
        </div>
    )
}));

export const BaseCardHeaderTitle = styled(_BaseCardHeaderTitle)`
  flex: 1 1 auto;
  text-align: center;
  font-weight: 500;
`;