import {IRootStore} from "../routes/root-store";
import {ClientDataset} from "../components/dataset/clientdataset";
import {action, computed, observable, runInAction} from "mobx";
import axios from "axios";
import {_loginColumns, ILogin} from "../models/_Login";
import jwt_decode from 'jwt-decode'
import {IUsergroupRight, usergrouprightColumns} from "../models/UsergroupRight";
import {IDataset} from "../components/dataset/IDataset";
import {Dataset} from "../components/dataset/dataset";
import {IUser, userColumns} from "../models/User";
import {IModulNames} from "./modul-names";
import {includes} from "../components/lib/Includes";
import {INavRoute, routes} from "../routes/routes";
import * as R from 'ramda';
import {UsergroupRight} from "../models/rights/enum-usergrouprights";
import {_passwordColumns, IPassword} from "../models/_Password";
import {RouterState} from "mobx-state-router";
import {configColumns, IConfig} from "../models/Config";


export interface IAuthStore {
    rootStore: IRootStore;
    username: string;

    doRegister: (username: string, password: string) => void;
    doLogin: (username: string, password: string) => void;
    doLogout: () => void;
    loggedIn: boolean;
    headers: () => object;
    cdsLogin: ClientDataset<any>;
    lastMessage: string;
    //
    sidebarRoutes: INavRoute[];
    toggelOpenRoute: (actRoute: INavRoute) => void;
    userRights: boolean[];
    modules: IModulNames[];
    hasModuleRight: (moduleRight: IModulNames) => boolean;
    hasUserRight: (userRight: UsergroupRight) => boolean;
    getToken: () => any;
    loadUser: () => Promise<void>;
    config: IConfig;
    //
    onEnter: (fromState: RouterState, toState: RouterState) => Promise<void>;
    onExit: (fromState: RouterState, toState: RouterState) => Promise<void>;
    cdsPassword: ClientDataset<IPassword>;

}


export class AuthStore implements IAuthStore {

    rootStore: IRootStore;

    @observable
    username: string = '';

    @observable
    lastMessage: string = '';

    @observable
    cdsLogin: ClientDataset<ILogin>;

    @observable
    cdsPassword: ClientDataset<IPassword>;


    signInRedirect: string;

    dsUser: IDataset<IUser> = null;
    dsConfig: IDataset<IConfig> = null;

    dsUsergroupRight: IDataset<IUsergroupRight> = null;

    @action.bound
    async updateUsername() {
        this.username = this.usernameFromToken();
        await this.loadUser();
    }

    @observable
    userRights: boolean[] = [...Array(300).fill(false)];

    @observable
    sidebarRoutes: INavRoute[] = []


    @observable
    modules: IModulNames[] = [];

    @observable
    config: IConfig = null;


    constructor(rootStore: any) {
        this.rootStore = rootStore;
        this.cdsLogin = new ClientDataset<ILogin>(_loginColumns);
        this.cdsPassword = new ClientDataset<IPassword>(_passwordColumns);
        this.dsUser = new Dataset<IUser>('/gridApi/user/', userColumns);
        this.dsConfig = new Dataset<IConfig>('/gridApi/config/1', configColumns)
        this.dsUsergroupRight = new Dataset<IUsergroupRight>('/gridApi/usergroupright/', usergrouprightColumns);
    }


    /**
     * doRegister
     *
     * @param username
     * @param password
     */
    @action.bound
    async doRegister(username: string, password: string) {
        let resp: any = {};
        try {
            resp = await axios.post('/gridApi/registration', {username: username, password: password});

            console.log('xxx', resp.data);
        } catch {
            console.log('yyy', resp.data);

        }
    }


    /**
     * doLogin
     *
     * @param username
     * @param password
     */
    @action.bound
    async doLogin(username: string, password: string) {
        try {
            let response: any = await axios.post('/gridApi/login', {username: username, password: password});
            if (response.data.access_token) {
                runInAction(() => {
                    this.setToken(response.data.access_token);
                    this.username = username;
                    this.lastMessage = '';
                    this.loadUser().then(() => {
                        this.rootStore.routerStore.goTo(this.signInRedirect ? this.signInRedirect : 'home');
                    })
                });
            } else {
                runInAction(() => {
                    this.lastMessage = response.data.message
                    this.setToken('');
                    this.username = '';
                    this.userRights.forEach(() => false);
                    this.sidebarRoutes = routes
                        .filter((route) => includes([], route.name));
                });
            }
        } catch {
            runInAction(() => {
                this.setToken('');
                this.username = '';
                this.userRights.forEach(() => false);
                this.sidebarRoutes = routes
                    .filter((route) => includes([], route.name));
            })
        }
    }

    /**
     * doLogout
     *
     * performes a logout. empties the sessionstorage Token
     */
    @action.bound
    doLogout() {
        this.setToken('');
        this.username = '';
        this.userRights.forEach(() => false);
        this.sidebarRoutes = routes
            .filter((route) => includes([], route.name));
        this.rootStore.routerStore.goTo('login');
    }


    @computed
    get loggedIn() {
        return this.username !== '';

    }


    /**
     * setToken
     *
     * Stores the auth token to sessionStorage
     *
     * @param idToken
     */
    setToken = (idToken: string) => {
        sessionStorage.setItem('id_token', idToken);
    };

    /**
     * getToken
     *
     * Returns the auth token or '' from sessionStorage
     */
    getToken = (): string =>
        sessionStorage.getItem('id_token');

    /**
     * headers
     *
     * Returns the authorization header
     */
    headers = (): object => {
        let h: any = {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        };
        if (this.loggedIn) {
            h['Authorization'] = "Bearer " + this.getToken();
        }
        return h;
    };

    setSignInRedirect = (toState: string) => {
        this.signInRedirect = toState;
    };


    usernameFromToken = (): string => {
        if (this.getToken()) {
            let decoded: any = jwt_decode(this.getToken());
            return decoded['identity'];
        } else {
            return '';
        }
    };

    /**
     *  Lädt User Konfiguration:
     *     - User Rechte
     *     - Module werden je nach Konfiguration freigeschaltet
     */
    @action.bound
    async loadUser() {
        if (this.username) {
            this.dsUser.filter = {username: this.username};
            await this.dsUser.open();
            await this.loadModules();
            await this.dsConfig.open();
            this.dsUsergroupRight.filter = {usergroupno: this.dsUser.actual ? this.dsUser.actual.usergroupno : ''};
            await this.dsUsergroupRight.open();

            runInAction('UpdateSidebarMenu', () => {
                ///////
                if (this.dsUser.actual?.language === 1) {
                    this.rootStore.langStore.setLanguage('Lang_de')
                } else {
                    this.rootStore.langStore.setLanguage('Lang_en')
                }
                ////////
                if (this.dsUsergroupRight.data.length > 0) {
                    this.userRights = this.dsUsergroupRight.data.map(
                        (userRight) => userRight.enabled
                    );
                } else {
                    this.userRights.forEach(() => false)
                }
                // module zuschalten
                let baseroutes = ['_configuration', '_auth', 'home', '_administration', '_basedata', '_project', '_action'];
                if (R.includes('PPAP', this.modules)) {
                    baseroutes.push('_ppap')
                }
                if (R.includes('AUDIT', this.modules)) {
                    baseroutes.push('_audit')
                }
                if (R.includes('IM', this.modules)) {
                    baseroutes.push('_inspection')
                }
                if (R.includes('GW', this.modules)) {
                    baseroutes.push('_gemba')
                }
                if (R.includes('OFFLINE', this.modules)) {
                    baseroutes.push('_activity')
                }
                if (R.includes('MOBILE', this.modules)) {
                    baseroutes.push('_mobile')
                }
                this.sidebarRoutes = routes
                    .filter((route) => includes(baseroutes, route.name))
                    .filter((route) => route.isNavBar)
                    .filter(route => (route.userRight || route.userRight === 0) ? this.userRights[route.userRight] : false);

                // Config laden
                this.config = this.dsConfig.actual;
            })
        } else {
            this.userRights = [...Array(300).fill(false)];
            this.sidebarRoutes = routes
                .filter((route) => includes([], route.name));
            return Promise.resolve();
        }
    }





    // modules and UserRights

    @action.bound
    async loadModules() {

        const config = {
            headers: {
                'Cache-Control': 'no-cache, no-store, must-revalidate',
                'Pragma': 'no-cache',
                'Expires': '0'
            }
        };

        let rights: any = await axios.get('/gridApi/modulerights', config);
        runInAction(() => {
            this.modules = rights.data.moduleRights;
        })
    }


    hasModuleRight = (moduleRight: IModulNames): boolean =>
        includes(this.modules, moduleRight);

    hasUserRight = (userRight: UsergroupRight): boolean => {
        return this.userRights[userRight];
    }

    // fromState: RouterState, toState: RouterState
    async onEnter() {
        this.rootStore.appStore.closeSidebar();

        await this.cdsPassword.open();
        this.cdsPassword.insert();
    }

    // fromState: RouterState, toState: RouterState
    async onExit() {
        this.cdsPassword.close()
        await Promise.resolve();
    }


    /**
     *  Klappt auf/zu aktuelle Route in sidebar
     *  Es kann nur eine offene Route geben.
     * @param actRoute
     */
    @action.bound
    toggelOpenRoute(actRoute: INavRoute) {
        this.sidebarRoutes
            .forEach(route => {
                if (route !== actRoute) {
                    route.isOpen = false;
                }
            })
    }

}

