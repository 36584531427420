import React, {KeyboardEvent} from 'react';
import {inject, observer} from "mobx-react";
import {Col, Row} from "reactstrap";
import {IBaseView} from "../_base/base-view";
import {BaseCardForm} from "../_base/form/base-card-form";
import styled from "styled-components";
import {IconButton} from "../_base/styled/icon-button";


@inject('rootStore')
@observer
class Login extends React.Component<IBaseView, {}> {


    onClick = async () => {
        const {
            rootStore: {
                authStore: {cdsLogin, doLogin},
            },

        } = this.props;
        await doLogin(cdsLogin.actual.username, cdsLogin.actual.password);
    };

    handleEnter = async (event: KeyboardEvent<HTMLImageElement>) => {
        if (event.key === "Enter") {
            await this.onClick();
            return;
        }

    };


    render() {
        const {
            rootStore: {
                authStore: {cdsLogin, lastMessage},
                langStore: {lang},
            },
            className
        } = this.props;
        return (
            <div className={className}>
                <Row style={{alignItems: 'center', height: '100%', justifyContent: 'center'}}
                     onKeyUp={this.handleEnter}>
                    <Col md={3}>
                        <BaseCardForm
                            title='LOGIN'
                            ds={cdsLogin}
                            colCount={1}
                            height={190}
                            message={lastMessage}
                            footerContent={
                                <Row style={{
                                    display: 'flex',
                                    alignItems: 'flex-end',
                                    height: '100%',
                                    width: '100%',
                                    justifyContent: 'center',
                                    backgroundColor: 'white',
                                    margin: 0
                                }}>
                                    <div style={{flex: '0 0 auto'}}>
                                        <IconButton id="login-btn" color="primary"
                                                    onClick={this.onClick}>{lang.LOGIN}</IconButton>
                                    </div>
                                </Row>
                            }
                        />
                    </Col>
                </Row>
            </div>
        )
    }
}

export const LoginView = styled(Login)`
  background-color: ${props =>props.theme.bg};
  background-image: url("/img/background.jpg");
  background-repeat: no-repeat, repeat;
  background-size: cover;
  width: 100%;
  height: 100%;
  position: relative;
`;